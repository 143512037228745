<template>
  <div>
    <div v-if="user.institutional != null"></div>
    <div v-else>
      <b-card v-if="!orders.length">
        <div class="check-transaction mt-4">
          <b-row class="justify-content-center text-center">
            <b-col class="my-auto">
              <p class="title">Belum ada transaksi</p>
              <b-button to="/reksadana" class="btn-buy px-5 py-3">
                Mulai Investasi
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>

    <b-card
      v-for="(value, index) in orders.slice(0, 1)"
      :key="index"
      class="transaction-history-detail mb-4"
    >
      <b-row>
        <div class="transaction-title">
          <p>
            <b-icon
              class="ml-3 mr-2 text-muted"
              style="color: #e60012 !important"
              icon="arrow-left-right"
            ></b-icon
            >Transaksi Dalam Proses
          </p>
        </div>
      </b-row>
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-row class="my-2">
            <b-col>
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="100%" class="my-2"></b-skeleton>
              <b-skeleton width="100%"></b-skeleton>
            </b-col>
            <b-col>
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="100%" class="my-2"></b-skeleton>
              <b-skeleton width="100%"></b-skeleton>
            </b-col>
          </b-row>
          <div class="my-4">
            <b-skeleton width="100%"></b-skeleton>
          </div>
          <div class="my-4">
            <b-skeleton width="100%"></b-skeleton>
          </div>
        </template>

        <div class="fund-name mb-2">
          <b-row>
            <div class="col-lg-12 col-md-6 my-auto">
              <b-row>
                <div class="col-lg-12 col-md-6">
                  <div v-if="value.id_transaction_type == 1" class="my-auto">
                    <div class="row">
                      <div class="col-lg-12 col-md-6">
                        <div class="row">
                          <div class="col-lg-2 col-md-6 mb-2">
                            <div v-if="value.product.image == null">
                              <img
                                :src="
                                  'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                  value.product.fund_name
                                "
                              />
                            </div>
                            <div v-else>
                              <img
                                :src="`${imageCore}/images/products/${value.product.image}`"
                                alt="Fund Image"
                              />
                            </div>
                          </div>
                          <div class="col-lg-10 col-md-6">
                            <div v-if="value.status != 'waiting transfer'">
                              <b-button
                                class="btn-modal-portfolio mx-2"
                                :to="
                                  '/order/subscription-status/' +
                                  value.code +
                                  '/' +
                                  value.fund_code
                                "
                              >
                                <h4>
                                  {{
                                    value.product ? value.product.fund_name : ""
                                  }}
                                </h4>
                              </b-button>
                            </div>
                            <div v-else>
                              <b-button
                                class="btn-modal-portfolio"
                                :to="
                                  '/order/subscription-checkout/' +
                                  value.code +
                                  '/' +
                                  value.fund_code
                                "
                              >
                                <h4>
                                  {{
                                    value.product ? value.product.fund_name : ""
                                  }}
                                </h4>
                                <p class="mt-n1">
                                  {{
                                    value.product ? value.product.fund_type : ""
                                  }}
                                </p>
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="value.id_transaction_type == 2"
                    class="my-auto"
                  >
                    <div class="row">
                      <div class="col-lg-12 col-md-6">
                        <div class="row">
                          <div class="col-lg-2 col-md-6 mb-2">
                            <div v-if="value.product.image == null">
                              <img
                                :src="
                                  'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                  value.product.fund_name
                                "
                              />
                            </div>
                            <div v-else>
                              <img
                                :src="`${imageCore}/images/products/${value.product.image}`"
                                alt="Fund Image"
                              />
                            </div>
                          </div>
                          <div class="col-lg-10 col-md-6">
                            <b-button
                              class="btn-modal-portfolio"
                              :to="
                                '/order/redeem-status/' +
                                value.code +
                                '/' +
                                value.fund_code
                              "
                            >
                              <h4>
                                {{
                                  value.product ? value.product.fund_name : ""
                                }}
                              </h4>
                              <p class="mt-n1">
                                {{
                                  value.product ? value.product.fund_type : ""
                                }}
                              </p>
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="row">
                      <div class="col-lg-12 col-md-6">
                        <div class="row">
                          <div class="col-lg-2 col-md-6 mb-2">
                            <div v-if="value.product_out.image == null">
                              <img
                                :src="
                                  'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                  value.product_out.fund_name
                                "
                              />
                            </div>
                            <div v-else>
                              <img
                                :src="`${imageCore}/${value.product_out.image}`"
                                alt="Fund Image"
                              />
                            </div>
                          </div>
                          <div class="col-lg-10 col-md-6">
                            <div class="switch-from">
                              <p>Dari</p>
                            </div>
                            <b-button
                              class="btn-modal-portfolio mt-n3"
                              :to="
                                '/order/switching-history/' +
                                user.individual.ifua +
                                '/' +
                                value.id +
                                '/' +
                                value.id_transaction_type
                              "
                            >
                              <h4>
                                {{
                                  value.product_out
                                    ? value.product_out.fund_name
                                    : ""
                                }}
                              </h4>
                              <p class="mt-n1">
                                {{
                                  value.product_out
                                    ? value.product_out.fund_type
                                    : ""
                                }}
                              </p>
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-6">
                        <div class="row">
                          <div class="col-lg-2 col-md-6 mb-2">
                            <div v-if="value.product_in.image == null">
                              <img
                                :src="
                                  'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                  value.product_in.fund_name
                                "
                              />
                            </div>
                            <div v-else>
                              <img
                                :src="`${imageCore}/${value.product_in.image}`"
                                alt="Fund Image"
                              />
                            </div>
                          </div>
                          <div class="col-lg-10 col-md-6">
                            <div class="switch-to">
                              <p>Ke</p>
                            </div>
                            <b-button
                              class="btn-modal-portfolio mt-n3"
                              :to="
                                '/order/switching-history/' +
                                user.individual.ifua +
                                '/' +
                                value.id +
                                '/' +
                                value.id_transaction_type
                              "
                            >
                              <h4>
                                {{
                                  value.product_in
                                    ? value.product_in.fund_name
                                    : ""
                                }}
                              </h4>
                              <p class="mt-n1">
                                {{
                                  value.product_in
                                    ? value.product_in.fund_type
                                    : ""
                                }}
                              </p>
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-row>
              <!-- <div v-if="value.id_transaction_type != 3">
                <div v-if="value.product.image == null">
                  <img
                    :src="
                      'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                      value.product.fund_name
                    "
                  />
                </div>
                <div v-else>
                  <img
                    :src="`${imageCore}/${value.product.image}`"
                    alt="Fund Image"
                  />
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <div class="col-lg-12 col-md-6">
                    <div v-if="value.product_out.image == null">
                      <img
                        :src="
                          'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                          value.product_out.fund_name
                        "
                      />
                    </div>
                    <div v-else>
                      <img
                        :src="`${imageCore}/${value.product_out.image}`"
                        alt="Fund Image"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6">
                    <div v-if="value.product_in.image == null">
                      <img
                        :src="
                          'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                          value.product_in.fund_name
                        "
                      />
                    </div>
                    <div v-else>
                      <img
                        :src="`${imageCore}/${value.product_in.image}`"
                        alt="Fund Image"
                      />
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <!-- <b-col class="text-left">
              <div v-if="value.id_transaction_type == 1">
                <div v-if="value.status != 'waiting transfer'">
                  <b-button
                    class="btn-modal-portfolio mx-2"
                    :to="
                      '/order/subscription-status/' +
                      value.code +
                      '/' +
                      value.fund_code
                    "
                  >
                    <h4>
                      {{ value.product ? value.product.fund_name : "" }}
                    </h4>
                  </b-button>
                </div>
                <div v-else>
                  <b-button
                    class="btn-modal-portfolio mx-2"
                    :to="
                      '/order/subscription-checkout/' +
                      value.code +
                      '/' +
                      value.fund_code
                    "
                  >
                    <h4>
                      {{ value.product ? value.product.fund_name : "" }}
                    </h4>
                  </b-button>
                </div>
              </div>
              <div v-else-if="value.id_transaction_type == 2">
                <b-button
                  class="btn-modal-portfolio mx-2"
                  :to="
                    '/order/redeem-status/' + value.code + '/' + value.fund_code
                  "
                >
                  <h4>
                    {{ value.product ? value.product.fund_name : "" }}
                  </h4>
                </b-button>
              </div>
              <div v-else>
                <div class="row">
                  <div class="col-lg-12 col-md-6">
                    <b-button
                      class="btn-modal-portfolio mb-2 mx-2"
                      :to="'/order/switching-status/' + value.code"
                    >
                      <div class="switch-from">
                        <p>Dari</p>
                      </div>
                      <h4 class="text-left">
                        {{
                          value.product_out ? value.product_out.fund_name : ""
                        }}
                      </h4>
                    </b-button>
                  </div>

                  <div class="col-lg-12 col-md-6">
                    <b-button
                      class="btn-modal-portfolio mx-2"
                      :to="'/order/switching-status/' + value.code"
                    >
                      <div class="switch-to mt-3">
                        <p>Ke</p>
                      </div>
                      <h4 class="text-left">
                        {{ value.product_in ? value.product_in.fund_name : "" }}
                      </h4>
                    </b-button>
                  </div>
                </div>
              </div>
            </b-col> -->
          </b-row>
        </div>
        <div class="total-investment py-4">
          <b-row>
            <b-col>
              <div v-if="value.id_transaction_type == 1">
                <p><b>Nilai Investasi</b></p>
                <p class="mt-n2">
                  {{ value.product ? value.product.fund_ccy : ""
                  }}{{ formatPrice(value.amount_nominal) }}
                </p>
              </div>
              <div v-else-if="value.id_transaction_type == 2">
                <p><b>Nilai Penjualan</b></p>
                <p class="mt-n2">
                  {{ value.product ? value.product.fund_ccy : "" }}
                  {{
                    formatPrice(
                      value.amount_nominal != null
                        ? value.amount_nominal
                        : value.amount_unit * value.nav_per_unit
                    )
                  }}
                </p>
              </div>
              <div v-else>
                <p><b>Nilai Pengalihan</b></p>
                <p class="mt-n2">
                  {{ value.product_out ? value.product_out.fund_ccy : ""
                  }}{{
                    formatPrice(
                      value.switch_out_nominal != null
                        ? value.switch_out_nominal
                        : value.switch_out_unit * value.nav_per_unit
                    )
                  }}
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="subscription">
          <b-row>
            <b-col>
              <div v-if="value.id_transaction_type == 1">
                <p><b>Pembelian</b></p>
                <p class="mt-n2">
                  {{
                    value.status === "waiting confirm"
                      ? "Menunggu Verifikasi"
                      : value.status == "waiting sales"
                      ? "Menunggu Konfirmasi"
                      : value.status == "approve"
                      ? "Dalam Proses"
                      : "Menunggu Pembayaran"
                  }}
                </p>
              </div>
              <div v-else-if="value.id_transaction_type == 2">
                <p><b>Penjualan</b></p>
                <p class="mt-n2">
                  {{
                    value.status === "waiting confirm"
                      ? "Menunggu Verifikasi"
                      : value.status == "waiting sales"
                      ? "Menunggu Konfirmasi"
                      : value.status == "approve"
                      ? "Dalam Proses"
                      : "null"
                  }}
                </p>
              </div>
              <div v-else>
                <p><b>Pengalihan</b></p>
                <p class="mt-n2">
                  {{
                    value.status === "waiting confirm"
                      ? "Menunggu Verifikasi"
                      : value.status == "waiting sales"
                      ? "Menunggu Konfirmasi"
                      : value.status == "approve"
                      ? "Dalam Proses"
                      : "null"
                  }}
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="status mb-4">
          <b-row>
            <b-col class="my-auto">
              <div v-if="value.status == 'waiting transfer'">
                <div v-if="value.id_transaction_type == 1">
                  <div
                    class="col-lg-12 col-md-6 my-auto text-left text-lg-right"
                  >
                    <b-button
                      :to="
                        '/order/subscription-checkout/' +
                        value.code +
                        '/' +
                        value.fund_code
                      "
                      class="btn-buy py-2 px-4"
                    >
                      Bayar
                    </b-button>
                  </div>
                </div>
              </div>
              <div
                v-else-if="
                  value.status == 'waiting confirm' || value.status == 'approve'
                "
              >
                <div class="col-lg-12 col-md-6 my-auto text-left text-lg-right">
                  <div class="estimation text-right">
                    <p class="ml-0">Estimasi Selesai</p>
                    <p class="mt-n2 text-right text-lg-right ml-3">
                      3 hari bursa <br />
                      <small>setelah tanggal transaksi</small>
                    </p>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <hr />

        <hr />
        <div class="check-transaction mt-4">
          <b-link to="/orders">
            <b-row>
              <b-col class="my-auto">
                <p class="title">Lihat Semua Aktivitas</p>
              </b-col>
              <b-col class="my-auto value">
                <b-icon icon="chevron-right"></b-icon>
              </b-col>
            </b-row>
          </b-link>
        </div>
      </b-skeleton-wrapper>
    </b-card>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters } from "vuex";
import instance from "@/utils/api";

export default {
  name: "TransactionHistorySidebar",
  mounted() {
    setInterval(() => {
      this.date = moment(this.date.subtract(1, "seconds"));
    }, 1000);
    this.fetchOrders();
  },
  created() {
    this.$_loadingTimeInterval = null;
  },
  data() {
    return {
      date: moment(2460 * 60 * 1000),
      loading: false,
      loadingTime: 1,
      maxLoadingTime: 1,
      orders: [],
      imageCore: process.env.VUE_APP_IMAGE_URL,
    };
  },
  computed: {
    time: function () {
      return this.date.format("HH:mm:ss");
    },
    ...mapGetters({
      user: "auth/user",
      app_url: "app_url",
      access_token: "auth/access_token",
    }),
  },
  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearLoadingTimeInterval();

        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            this.loadingTime++;
          }, 1000);
        }
      }
    },
    loadingTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === this.maxLoadingTime) {
          this.loading = false;
        }
      }
    },
  },
  methods: {
    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },

    async fetchOrders() {
      if (this.user.institutional != null) {
        this.loading = true;
        let response = await instance({
          method: "GET",
          url: `${this.app_url}order-get?ifua_number=${this.user.institutional.ifua}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        this.orders = response.data.data;
        this.loadingTime = 0;
      } else {
        this.loading = true;
        let response = await instance({
          method: "GET",
          url: `${this.app_url}order-get?ifua_number=${this.user.individual.ifua}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        this.orders = response.data.data;
        this.loadingTime = 0;
      }
    },
  },
};
</script>

<style lang="scss" scooped>
h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #4b5563;
}

hr {
  margin: 0 !important;
}
.transaction-history-detail {
  .transaction-title p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }

  .fund-name {
    img {
      border-radius: 6px;
      width: 40px;
    }

    h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      text-align: left;
      color: #5c0dab;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      text-align: left;
      color: #6b7280;
    }
  }

  .total-investment {
    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #4b5563;
    }
    .value {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      text-align: right;
      color: #111827;
    }
  }

  .subscription {
    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #4b5563;
    }
    .value {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      text-align: right;
      color: $secondaryColor;
    }
  }

  .status {
    .countdown {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;

      color: $secondaryColor;
    }
  }

  .estimation-complete {
    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #4b5563;
    }
    .value {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      text-align: right;
      color: #111827;
    }
  }

  .check-transaction {
    a {
      text-decoration: none;
    }
    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #6b7280;
    }

    .value {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      text-align: right;
      color: #9ca3af;
    }
  }
}

.btn-buy {
  font-family: $inter;
  background: $secondaryColor;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  border: none;
  text-align: center;
  color: white;
}

.btn-buy:hover {
  background: $hoverSecondary;
}

.btn-buy:focus {
  box-shadow: none !important;
}

.btn-buy:active {
  background: $hoverSecondary !important;
  outline: none !important;
  color: white !important;
}

.btn-modal-portfolio {
  font-family: $inter;
  background: transparent;
  padding: 0;
  border: none;

  .switch-from {
    p {
      text-align: left;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #4b5563;
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  }
  .switch-to {
    p {
      text-align: left;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #4b5563;
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.btn-modal-portfolio:hover {
  background: transparent;
  padding: 0;
  border: none;
}
.btn-modal-portfolio:focus {
  box-shadow: none !important;
}

.btn-modal-portfolio:active {
  background: transparent !important;
  outline: none !important;
}
</style>