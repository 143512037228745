<template>
  <div>
    <b-card class="portfolio-detail mb-4">
      <b-row>
        <div class="portfolio-title">
          <p>
            <b-icon
              class="ml-3 mr-2 text-muted"
              style="color: #e60012 !important"
              icon="file-bar-graph"
            ></b-icon
            >Portfolio
          </p>
        </div>
      </b-row>
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-row class="my-2">
            <b-col>
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="100%" class="my-2"></b-skeleton>
              <b-skeleton width="100%"></b-skeleton>
            </b-col>
            <b-col>
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="100%" class="my-2"></b-skeleton>
              <b-skeleton width="100%"></b-skeleton>
            </b-col>
          </b-row>
          <div class="my-4">
            <b-skeleton width="100%"></b-skeleton>
          </div>
          <div class="my-4">
            <b-skeleton width="100%"></b-skeleton>
          </div>
        </template>
        <div class="total-investment">
          <b-row>
            <b-col>
              <p class="title">Total Investasi</p>
            </b-col>
            <b-col class="my-auto">
              <p class="value">IDR{{ formatPrice(sumNominalInvest) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="initial-investment">
          <b-row>
            <b-col>
              <p class="title">Modal Investasi</p>
            </b-col>
            <b-col class="my-auto">
              <p class="value">IDR{{ formatPrice(modalInvestasiNominal) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="profit-loss">
          <b-row>
            <b-col>
              <p class="title">Untung/Rugi</p>
            </b-col>
            <b-col class="my-auto">
              <p class="value">
                <span
                  :class="{
                    'text-success': untungPersen >= 0,
                    'text-danger': untungPersen < 0,
                  }"
                >
                  <template v-if="untungPersen >= 0">
                    <b-icon icon="caret-up-fill"></b-icon>
                  </template>
                  <template v-else>
                    <b-icon icon="caret-down-fill"></b-icon>
                  </template>
                  {{ untungPersen | formatPercent }}%
                </span>
                IDR{{ formatPrice(untungRupiah) }}
              </p>
            </b-col>
          </b-row>
        </div>
        <b-button block class="btn-tertiary mb-2" to="/reksadana"
          >Investasi Sekarang</b-button
        >

        <div class="check-portfolio">
          <b-link to="/portfolio">
            <b-row>
              <b-col class="my-auto">
                <p class="title mt-3">Lihat Portfolio</p>
              </b-col>
              <b-col class="my-auto value">
                <b-icon icon="chevron-right"></b-icon>
              </b-col>
            </b-row>
          </b-link>
        </div>
      </b-skeleton-wrapper>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import instance from "@/utils/api";

export default {
  name: "PortfolioSidebar",
  data: () => ({
    products: [],
    userPortfolios: [],
    portoDetail: [],
    portfolios: [],
    fund_types: [],
    modalInvestasi: [],
    date_performa_nav: "",
    loading: false,
    loadingTime: 0,
    maxLoadingTime: 1,
    sumNominalInvest: null,
    modalInvestasiNominal: null,
  }),
  mounted() {
    document.title = "Profil | CGS iTrade Fund";
  },
  computed: {
    ...mapGetters({
      access_token: "auth/access_token",
      bearer_token: "bearer_token",
      app_url: "app_url",
      check: "auth/check",
      user: "auth/user",
    }),

    untungRupiah: function () {
      return this.sumNominalInvest - this.modalInvestasiNominal;
    },

    untungPersen: function () {
      return (
        ((this.sumNominalInvest - this.modalInvestasiNominal) /
          this.sumNominalInvest) *
        100
      );
    },
  },

  created() {
    this.fetchFunds();
    this.fetchPortfolios();
    this.$_loadingTimeInterval = null;
  },
  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearLoadingTimeInterval();

        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            this.loadingTime++;
          }, 1000);
        }
      }
    },
    loadingTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === this.maxLoadingTime) {
          this.loading = false;
        }
      }
    },
  },
  methods: {
    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    async fetchFunds() {
      this.loading = true;
      let response = await axios({
        method: "GET",
        url: "core/nav-history?api=1&type=1",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.bearer_token}`,
        },
      });
      this.products = response.data.dataNav;
      this.date_performa_nav = response.data.nav_date;
      this.loadingTime = 0;
    },

    async fetchPortfolios() {
      this.loading = true;
      if (this.user.institutional == null) {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}core/portfolio-detail?type=Individual&id=${this.user.individual.id}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        this.userPortfolios = response.data.data.userPortfolio;
        this.portoDetail = response.data.data.porttoDetail;
      } else {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}core/portfolio-detail?type=Institutional&id=${this.user.institutional.id}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        this.userPortfolios = response.data.data.userPortfolio;
        this.portoDetail = response.data.data.porttoDetail;
      }

      this.sumNominalInvest = this.userPortfolios.reduce((sum, portfolio) => {
        return (sum += parseFloat(portfolio.amount));
      }, 0);

      this.userPortfolios.map((portfolio) => {
        let detail = this.portoDetail[portfolio.product_id];

        let modalInvestasi = //nominal perolehan
          parseFloat(detail.nav_perolehan) *
          parseFloat(portfolio.outstanding_unit_balance);

        let return_rupiah = portfolio.amount - modalInvestasi;
        let return_persen = (return_rupiah / modalInvestasi) * 100;

        this.modalInvestasiNominal += parseFloat(modalInvestasi);

        this.portfolios.push({
          modalInvestasi: modalInvestasi,
          return_rupiah: return_rupiah,
          return_persen: return_persen,
          amount: portfolio.amount,
          fund_name: portfolio.product.fund_name,
          fund_type: portfolio.product.fund_type,
          fund_code: portfolio.product.fund_code,
          im_id: portfolio.product.im_id,
          outstanding_unit_balance: portfolio.outstanding_unit_balance,
          nav_per_unit: portfolio.nav_per_unit,
        });

        return portfolio;
      });

      this.loadingTime = 0;
    },
  },
};
</script>

<style lang="scss" scooped>
h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4b5563;
}

hr {
  margin: 0 !important;
}

.portfolio-detail {
  .portfolio-title p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }

  .total-investment {
    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #374151;
    }
    .value {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      text-align: right;
      color: #111827;
    }
  }

  .initial-investment {
    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #374151;
    }
    .value {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      text-align: right;
      color: #111827;
    }
  }

  .profit-loss {
    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #374151;
    }
    .value {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      text-align: right;
      color: #111827;
    }
  }

  .check-portfolio {
    a {
      text-decoration: none;
    }
    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #6b7280;
    }

    .value {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      text-align: right;
      color: #9ca3af;
    }
  }
}

.transaction-history-detail {
  .transaction-title p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }

  .fund-name {
    img {
      border-radius: 6px;
      width: 40px;
    }

    h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;

      color: #5c0dab;
      margin-bottom: 4px;
      margin-top: 8px;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 9px;

      color: #6b7280;
    }
  }

  .total-investment {
    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #4b5563;
    }
    .value {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      text-align: right;
      color: #111827;
    }
  }

  .subscription {
    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #4b5563;
    }
    .value {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      text-align: right;
      color: $secondaryColor;
    }
  }

  .status {
    .countdown {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;

      color: $secondaryColor;
    }
  }

  .estimation-complete {
    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #4b5563;
    }
    .value {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      text-align: right;
      color: #111827;
    }
  }

  .check-transaction {
    a {
      text-decoration: none;
    }
    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #6b7280;
    }

    .value {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      text-align: right;
      color: #9ca3af;
    }
  }
}

@media only screen and (max-width: 768px) {
  p {
    font-size: 14px !important;
  }
}
</style>